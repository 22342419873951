





















































































import { Component, Vue } from "vue-property-decorator";
import { ISceneUpdate } from "@/interfaces";
import { dispatchUpdateScene, dispatchGetScene } from "@/store/main/actions";
import { readScene} from '@/store/main/getters';

@Component
export default class Edit extends Vue {
  public loading = true;
  public valid = false;
  public default = {
    light: 0,
    x_color: 0,
    y_color: 0,
  }

  public scene: ISceneUpdate = {
    name: '',
    light: 0,
    intensity: 0,
    color: '',
    x_color: 0,
    y_color: 0,
    color_type: 'cct',
  };

  get sceneDetail() {
    return readScene(this.$store);
  }

  public reset() {
    this.scene = <ISceneUpdate>{ ...this.sceneDetail };
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async mounted() {
    await dispatchGetScene(this.$store, Number(this.$router.currentRoute.params.id));
    this.scene = <ISceneUpdate>{ ...this.sceneDetail };
    this.default = <any>{ ...this.sceneDetail };
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      await dispatchUpdateScene(this.$store, {sceneId: Number(this.$router.currentRoute.params.id), data:this.scene});
      // this.$router.back();
    }
  }
}
